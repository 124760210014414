import React from "react";

export default function Component(props: {
})
{
  return (
    <>
      <h1>RiNgLy</h1>
    </>
  );
}
