import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { AppConfig } from "."; 
import { RouteProps, useHistory, useLocation } from "react-router-dom";
import { FunctionComponent, useEffect } from "react";
import { useIsLoggedInQuery } from "./graphql/generated/graphql";
import Login from "./routes/Login";
import useServiceWorker from './shared/Hooks/useServiceWorker';

// ««·· imports
import Home from "./routes/Home";
import Layout from './shared/Layout';
// ··»»

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
export default function Component(props: { appConfig: AppConfig })
{
  // eslint-disable-next-line
  const serviceWorkerState = useServiceWorker();
  
  const { data } = useIsLoggedInQuery();

  const AuthorizedRoute: FunctionComponent<RouteProps> = (props) =>
  {
    const location = useLocation();
    const history = useHistory();

    useEffect(() =>
    {
      if(!data?.isLoggedIn)
      {
        history.replace(`/login?returnurl=${location.pathname}`);
      }
    });

    return <Route render={({ location }) => data?.isLoggedIn ? props.children : <></>} />
  };

  const queryToObject = (query: string) =>
  {
    const allParams = new URLSearchParams(query);
    let result: any = {};
    allParams.forEach((v, k) => result[k] = v);
    return result;
  };
  

  // ««·· render
  // ··»»

  return (
    <HashRouter basename={baseUrl!}>
      <Switch>
        {/* ««·· routes */}
        <AuthorizedRoute exact path="/">
          <Layout env={props.appConfig.env} serviceWorkerState={serviceWorkerState} >
            <Home />
          </Layout>
        </AuthorizedRoute>
        {/* # ··»» */}
        
        <Route exact path="/login">
          {({ location }) => <Login serviceWorkerState={serviceWorkerState} appConfig={props.appConfig} returnurl={queryToObject(location.search).returnurl} />}
        </Route>
      </Switch>
    </HashRouter>
  );
}
